.App {
    /*text-align: center;*/
    background-color: rgb(247, 247, 247);
    /*background-color: #f7f7f7;*/
    overflow: auto;
    font-size: 15px;
    font-family: メイリオ, Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Verdana, "MS P Gothic", "ＭＳ Ｐゴシック", sans-serif;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.titleMap {
    display: none;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.mapContainer {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.mapOneContainer {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.mapContent {
    display: block;
    margin: auto;
}

.buttonCustom {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    color: #fff;
    position: relative;
}

.buttonCustom:before {
    content: '';
    transition-property: right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    position: absolute;
    left: 0;
    right: 100%;
    height: 4px;
    bottom: 0;
    border-radius: 0 0 5px 5px;
}

.buttonCustom:focus {
    outline: none;
}

.buttonCustom:hover:before {
    right: 0;
}

.bg_gray {
    background-color: gray;
}

.bg_yellow {
    background-color: yellow;
}

.bg_red {
    background-color: red;
}

.bg_blue {
    background-color: blue;
}

.bg_green {
    background-color: green;
}

@media print {
    .print-hidden {
        display: none;
    }

    .titleMap {
        display: block;
        margin-left: 30px;
    }

    .mapContent {
        transform: scale(0.5) translate(-50%, -50%);
        /*width: 910px;*/
        /*height: 910px;*/
        display: block;
        margin: auto;
    }

    .mapContainer {
        width: 650px;
        height: 650px;
        margin: auto;
    }

    .mapOneContainer {
        width: 800px;
        height: 925px;
        margin: auto;
    }

    .process {
        width: 66% !important;
        display: table-cell !important;
    }

    .identity-tag {
        width: 34% !important;
        display: table-cell !important;
    }

    .task {
        width: 50% !important;
        display: table-cell !important;
    }
}
