.AppListPopup-CardActions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.AppListPopup-ServiceList {
    display: flex;
    justify-content: center;
}

.AppListPopup-BigAvatar {
    width: 60px;
    height: 60px;
}
