
.show-tag-box {
    padding: 0 0 0 10px;
    font-size: 13px;
    line-height: 20px;
    position: relative;
    border-radius: 0 6px 6px 0;
    -webkit-border-radius: 0 6px 6px 0;
    -moz-border-radius: 0 6px 6px 0;
    margin-bottom: 5px !important;
    background-color: transparent !important;
}

.show-tag-box label {
    display: block;
    padding-left: 25px;
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    border-right: 1px solid #d6d6d6;
    border-radius: 0 6px 6px 0;
    -webkit-border-radius: 0 6px 6px 0;
    -moz-border-radius: 0 6px 6px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: unset;
}

.show-tag-box::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 17px;
    width: 10px;
    height: 16px;
    background-image: url(./file-management.png);
    background-repeat: no-repeat;
}

.show-tag-box::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: #d6d6d6;
    width: 10px;
    bottom: 0;
    border-radius: 6px 0 0 6px;
    -webkit-border-radius: 6px 0 0 6px;
    -moz-border-radius: 6px 0 0 6px;
}

.show-tag-box.yellow label {
    border-color: #eed716;
}

.show-tag-box.yellow::after {
    background-color: #eed716;
}

.show-tag-box.yellow::before {
    background-position: -89px -293px;
}

.show-tag-box.blue label {
    border-color: #1870c3;
}

.show-tag-box.blue::after {
    background-color: #1870c3;
}

.show-tag-box.blue::before {
    background-position: -101px -293px;
}

.show-tag-box.red label {
    border-color: #f85454;
}

.show-tag-box.red::after {
    background-color: #f85454;
}

.show-tag-box.red::before {
    background-position: -114px -293px;
}

.show-tag-box.green label {
    border-color: #389916;
}

.show-tag-box.green::after {
    background-color: #389916;
}

.show-tag-box.green::before {
    background-position: -125px -293px;
}

.show-tag-box.gray label {
    border-color: #d6d6d6;
}

.show-tag-box.gray::after {
    background-color: #d6d6d6;
}

.show-tag-box.gray::before {
    background-position: -89px -316px;
}

/*.task-input:after{*/
/*    outline: none;*/
/*    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;*/
/*}*/
/*.task-input:hover{*/
/*    border-bottom: none !important;*/
/*}*/
/*.task-input:hover::before{*/
/*    border-bottom: 1px solid transparent;*/
/*}*/
.task-checkbox:checked {
    border: 2px solid red;
}

.task-checkbox {
    width: 23px;
    height: 23px;
    position: absolute;
    top: 3px;
}

.task-checkbox:hover {
    cursor: pointer;
}

.task-checked {
    position: absolute;
    transition: .2s;
    top: 5px;
    left: 7px;
    width: 8px;
    height: 14px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #26a69a;
    border-bottom: 2px solid #26a69a;
    transform: rotate(40deg);
}

.task-input {
    width: calc(100% - 35px);
    border: none;
    border-bottom: 1px solid;
    position: absolute;
    left: 30px;
    top: 0;
    height: 25px;
}

.task-input:focus {
    outline: none;
    border-bottom: 2px solid;
}

.task-input:hover {
    outline: none;
    border-bottom: 2px solid;
}

.year-input {
    width: 150px;
    height: 40px;
    text-align: center;
    border-radius: 5px;
    border: none;
    margin-left: -75px;
}

.year-info {
    margin-left: 20px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
}
